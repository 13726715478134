import React from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"

const ThankYouPage = () => (
  <Layout>
    <Seo title="Thank You!" />

    <div
      style={{ height: "700px" }}
      className="pt-16 pb-12 flex flex-col bg-white"
    >
      <div className="flex-grow flex flex-col justify-center max-w-7xl w-full mx-auto px-4 sm:px-6 lg:px-8">
        <div className="flex-shrink-0 flex justify-center">
          <a href="/" className="inline-flex">
            <span className="sr-only">Workflow</span>
          </a>
        </div>
        <div className="py-16">
          <div className="text-center">
            <p className="text-lg font-semibold text-indigo-600 uppercase tracking-wide">
              Thank you!
            </p>
            <h1 className="mt-5 text-4xl font-extrabold text-gray-900 tracking-tight sm:text-5xl">
              Submission Recieved
            </h1>
            <p className="mt-5 text-base text-gray-500">
              Thank you for contacting us at Qwarkle. We will get back to you
              shortly!
            </p>
          </div>
        </div>
      </div>
    </div>
  </Layout>
)

export default ThankYouPage
